<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<h5>
							<strong>Detalles de Producto</strong>
						</h5>
						<hr class="hrText" data-content="♦" />
					</div>
				</div>
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" tab="Generales">
						<productGeneralComponent />
					</a-tab-pane>
					<a-tab-pane key="2" tab="Imágenes" v-if="hasActualProduct">
						<productImages />
					</a-tab-pane>
				</a-tabs>
			</div>
		</div>
	</a-spin>
</template>

<script>
import productGeneralComponent from '@/components/products/general'
import productImages from '@/components/products/images'
import utilities from '@/services/utilities'
import { mapGetters } from 'vuex'

export default {
	name: 'productDetailView',
	components: {
		productGeneralComponent,
		productImages,
	},
	computed: {
		...mapGetters('products', ['actualProduct']),
		spinnerLoaderLabel() {
			return this.$store.state.products.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.products.spinnerLoader
		},
		hasActualProduct() {
			return !!utilities.objectValidate(this.actualProduct, 'sku', false)
		},
	},
	destroyed() {
		this.$store.commit('products/SET_STATE', {
			actualProduct: {},
		})
	},
}
</script>