<template>
	<div>
		<a-form class="mb-4" :form="form" @submit="handleSubmit">
			<div class="row">
				<!-- <div class="col-md-3">
					<a-form-item class="m0" label="¿Es consumo interno?">
						<a-select v-model="product.internal_use">
							<a-select-option value="true"> Sí </a-select-option>
							<a-select-option value="false"> No </a-select-option>
						</a-select>
					</a-form-item>
				</div> -->
				<!-- <div class="col-md-3" v-if="product.internal_use == 'false'">
					<a-form-item class="m0" label="¿Es servicio?">
						<a-select v-model="product.is_service">
							<a-select-option value="true"> Sí </a-select-option>
							<a-select-option value="false"> No </a-select-option>
						</a-select>
					</a-form-item>
				</div> -->
				<div class="col-md-6">
					<a-form-item class="m0" label="Nombre del producto">
						<a-input v-model="product.label" class="text-uppercase" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="SKU">
						<a-input-search placeholder="Código de barras o SKU del producto" ref="productSKU" v-model="product.sku" @search="onCreateSKU">
							<a-button type="primary" slot="enterButton">
								<a-icon type="setting" theme="filled" />
							</a-button>
						</a-input-search>
					</a-form-item>
				</div>
				<div class="col-md-3" v-if="product.internal_use == 'false'">
					<a-form-item class="m0" label="Clave SAT">
						<a-input v-model="product.sat_information.sat_key" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Precio de venta a público">
						<a-input prefix="$" v-money="money" v-model="product.prices.sale_price" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Precio de venta a distribuidor">
						<a-input prefix="$" v-money="money" v-model="product.prices.supplier_price" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Precio de venta a S2R">
						<a-input prefix="$" v-money="moneyS2R" v-model="product.prices.s2r_price" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Stock">
						<a-input type="number" v-model="product.stock" :disabled="isNewRecord" />
					</a-form-item>
				</div>
			</div>
			<!-- <div class="row" v-if="product.is_service == 'false'">
				<div class="col-md-12">
					<hr class="hrText" data-content="Tienda en Linea s2r.mx" />
				</div>
				<div class="col-md-3">
					<a-checkbox :checked="!!product.in_s2r_shop" @change="setShops('in_s2r_shop')"> Activo tienda en línea </a-checkbox>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Path WC">
						<a-input v-model="product.wc_path" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Descuento %">
						<a-input prefix="%" v-mask="'##'" v-model="product.wc_discount" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item class="m0" label="Precio tienda en línea">
						<a-input prefix="$" v-money="money" v-model="product.wc_price" />
					</a-form-item>
				</div>
			</div> -->
			<div class="row">
				<div class="col-md-12">
					<hr class="hrText" />
				</div>
				<div class="col-md-4 text-left">
					<a-button class="ml5 btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
				</div>
				<div class="col-md-8 text-right">
					<a-button icon="save" class="btn btn-success" htmlType="submit">Guardar</a-button>
				</div>
			</div>
		</a-form>
		<searchSATProductServiceComponent :visible="modal.visible" @success="onCloseModal" @selectedRecord="onSelectedRecord" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import { productModel } from '@/constants'
import _ from 'lodash'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import searchSATProductServiceComponent from '@/components/searchSATProductService'
import utilities from '@/services/utilities'
import numeral from 'numeral'
import moment from 'moment'

export default {
	name: 'product_generalComponent',
	components: {
		searchSATProductServiceComponent,
	},
	directives: {
		mask,
		money: VMoney,
	},
	computed: {
		...mapGetters('products', ['fullProductCategoriesList', 'actualProduct']),
		...mapGetters('measurementUnits', ['measurementUnitsList']),
		...mapGetters('suppliers', ['suppliersList']),
		...mapGetters('user', ['user']),
		spinnerLoader() {
			return this.$store.state.products.spinnerLoader
		},
		isPDVUser() {
			return this.user.user_id == 20 ? true : false
		},
		isNewRecord() {
			return !!utilities.objectValidate(this.$route, 'params.id', false)
		},
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
			money: {
				decimal: '.',
				thousands: ',',
				precision: 0,
			},
			moneyS2R: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			product: _.cloneDeep(productModel.general),
			modal: {
				visible: false,
				title: '',
			},
			subCategoriesList: [],
			disabledSubcategory: true,
		}
	},
	beforeMount() {
		if (utilities.objectValidate(this.$route, 'params.id', false)) {
			this.setLocalData()
		}
	},
	methods: {
		setLocalData() {
			this.product = _.cloneDeep({
				...productModel.general,
				label: this.actualProduct.label,
				sku: this.actualProduct.sku,
				stock: this.actualProduct.stock,
			})

			this.product.sat_information.sat_key = this.actualProduct.sat_information.sat_key
			this.product.prices.sale_price = numeral(this.actualProduct.prices.sale_price).format('0,0')
			this.product.prices.supplier_price = numeral(this.actualProduct.prices.supplier_price).format('0,0')
			this.product.prices.s2r_price = numeral(this.actualProduct.prices.s2r_price).format('0,0.00')
		},
		onCancel() {
			this.$router.replace('/almacen')
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		handleSubmit(e) {
			e.preventDefault()
			let errors = []

			if (!utilities.objectValidate(this.product, 'sku', false)) {
				errors.push('- Ingresa código de barras o SKU del producto')
			}
			if (!utilities.objectValidate(this.product, 'label', false)) {
				errors.push(`- Ingresa nombre del ${this.product.is_service == 'true' ? 'servicio' : 'producto'}`)
			}
			if (!utilities.objectValidate(this.product, 'prices.sale_price', false)) {
				errors.push('- Ingresa precio de venta para público en general')
			}
			if (!utilities.objectValidate(this.product, 'prices.supplier_price', false)) {
				errors.push('- Ingresa precio de venta para distribuidores')
			}
			// valida si está habilitada la bandera de tienda en linea y si no tiene un precio publicado
			let wc_price = numeral(_.clone(this.product.wc_price)).value()
			if (this.product.in_s2r_shop && !wc_price > 0) {
				errors.push('- Ingresa precio del producto para la tienda en linea.')
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					showCancelButton: false,
					confirmButtonText: 'Ok',
				})
				return false
			}

			if (utilities.objectValidate(this.$route, 'params.id', false)) {
				this.product.id = Number(this.$route.params.id)
				this.$store.dispatch('products/UPDATE', this.product).then((response) => {
					this.setLocalData()
				})
			} else {
				this.$store.dispatch('products/CREATE', this.product)
			}
		},
		onCreateSKU() {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas generar un SKU para este producto?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.product.sku = moment().valueOf()
				}
			})
		},
		onCloseModal() {
			this.modal.visible = false
		},
		openSATModal() {
			this.modal.visible = true
		},
		onSelectedRecord(record) {
			this.product.sat_information = {
				..._.cloneDeep(record),
				full_description: `${record.sat_key} - ${record.description}`,
			}
			this.onCloseModal()
		},
		onCategorySelected() {
			this.subCategoriesList = this.productSubCategoriesList.filter((e) => e.category_id == this.product.category)
			this.disabledSubcategory = false
		},
		getFullCategoriesList() {
			this.$store.dispatch('products/GET_CAT_SUBCAT_LIST')
		},
		getMeasurementUnitsList() {
			this.$store.dispatch('measurementUnits/GET')
		},
		setShops(type) {
			this.product[type] = !this.product[type]
		},
		getSuppliersList() {
			this.$store.dispatch('suppliers/GET_NO_REDIRECT')
		},
	},
	watch: {
		'product.is_service'(newValue) {
			let isService = newValue == 'true' ? true : false
			if (isService) {
				// 4 es el id de unidad de medida de servicio
				utilities.objectUpdate(this.product, 'measurement_unit_id', 4)
			} else {
				// 4 es el id de unidad de medida de pieza
				utilities.objectUpdate(this.product, 'measurement_unit_id', 1)
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>