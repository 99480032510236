<template>
	<div>
		<div class="row">
			<div class="col-md-12 text-center">
				<div class="clearfix">
					<a-upload-dragger name="files" :headers="uploadHeaders" :data="postData" :action="uploadURL" :multiple="true" :showUploadList="false" accept="image/*,video/*" @change="handleChange">
						<p class="ant-upload-drag-icon">
							<a-icon type="inbox" />
						</p>
						<p class="ant-upload-text">Haga click o arrastre el archivo a esta área para cargarlo.</p>
						<p class="ant-upload-hint">Solo se permiten archivos de video e imagenes</p>
					</a-upload-dragger>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-3 pt10" :key="index" v-for="(image, index) in actualProduct.images">
				<div class="card">
					<div class="card-body text-center">
						<img :src="image.url" :style="{ height: '150px' }" @click="handlePreview(image.url)" />
						<div class="pt5">
							<a-button icon="delete" size="small" type="danger" @click="onRemoveFile(image.id)"> </a-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<hr class="hrText" />
			</div>
			<div class="col-md-4 text-left">
				<a-button class="ml5 btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
			</div>
		</div>
		<a-modal :visible="previewVisible" :footer="null" title="Imagen de Producto" @cancel="handleCancel" width="80%">
			<div class="row">
				<div class="col-md-12 text-center">
					<img class="img-fluid" :src="previewImage" />
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import store from 'store'
import _ from 'lodash'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'

const API_URL = process.env.VUE_APP_API_URL

export default {
	name: 'product_images',
	computed: {
		...mapGetters('products', ['actualProduct']),
	},
	data() {
		return {
			previewVisible: false,
			previewImage: '',
			API_URL,
			uploadURL: `${API_URL}/api/warehouse/products/images`,
			uploadHeaders: {},
			postData: {},
		}
	},
	mounted() {
		const accessToken = store.get('accessToken')
		this.uploadHeaders = {
			Authorization: `Bearer ${accessToken}`,
		}
		this.postData = {
			id: this.actualProduct.id,
		}
		this.$store.dispatch('products/SET_STATE', {
			spinnerLoader: false,
		})
	},
	methods: {
		onCancel() {
			this.$router.replace('/almacen')
		},
		handleCancel() {
			this.previewVisible = false
		},
		handlePreview(file) {
			this.previewImage = file
			this.previewVisible = true
		},
		handleChange({ file }) {
			if (utilities.objectValidate(file, 'status', false)) {
				if (file.status == 'uploading') {
					this.$store.dispatch('products/SET_STATE', {
						spinnerLoader: true,
						spinnerLoaderLabel: 'Subiendo imagen',
					})
				}
				if (file.status == 'done') {
					this.$store.dispatch('products/SET_STATE', {
						spinnerLoader: false,
						actualProduct: {
							..._.cloneDeep(this.actualProduct),
							images: file.response.data,
						},
					})
					this.$store.dispatch('products/GET_ONE', {
						id: this.actualProduct.id,
					})
				}
			}
		},
		onRemoveFile(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas eliminar la imagen seleccionada?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					//
					this.$store.dispatch('products/DELETE_IMAGE', {
						image_id: id,
						product_id: this.actualProduct.id,
					})
				}
			})
		},
	},
}
</script>
